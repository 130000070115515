<template>
  <div class="pa-3">
    <v-card class="search-card ems-opacity-50-bg" elevation="0">
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row align="center">
            <v-col cols="12" sm="3">
              <v-select
                v-model="query.type"
                :items="typeOptions"
                solo
                :rules="[(v) => !!v || '此欄位為必填']"
              >
                <template v-slot:label>
                  報表類型<span class="error--text">*</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <reportDateRangeSelect
                v-model="selectDates"
                :type="query.type"
                solo
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3" class="pt-0">
              <v-select
                v-model="query.meterTypes"
                :items="meterTypeOption"
                item-value="id"
                item-text="name"
                solo
                :rules="[(v) => !!v || '此欄位為必填']"
              >
                <template v-slot:label>
                  電表類型<span class="error--text">*</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="3" class="pt-0">
              <inputSelectMultiple
                v-model="query.dist"
                :items="distOptions"
                placeholder="選擇行政區"
                show-select-all
                solo
                :rules="[(v) => !!v.length || '此欄位為必填']"
                color="accent"
              />
            </v-col>
            <v-col cols="12" sm="3" class="pt-0">
              <schoolSelect
                v-if="query.dist.length"
                :items="schoolOptions"
                v-model="query.schools"
                color="accent"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-4 pa-6" outlined @click="reset()" color="accent"
                >重設</v-btn
              >
              <v-btn
                class="py-6 px-14 accent--text"
                color="ems-aquamarine"
                @click="getSchoolMeters()"
                >查詢</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card
      class="ems-opacity-50-bg mt-6"
      elevation="0"
      v-if="meters && meters.length > 0"
    >
      <v-card-text>
        <dataTable
          v-model="meters"
          :searchDateFrom="selectDates[0]"
          :reportType="query.type"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import inputSelectMultiple from '@/components/inputSelectMultiple'
import reportDateRangeSelect from '@/components/reportDateRangeSelect'
import dataTable from './dataTable'
import schoolSelect from './schoolSelect'
import Meter from '@/api/ems/Meter'

export default {
  name: undefined,
  mixins: [],
  components: {
    inputSelectMultiple,
    dataTable,
    schoolSelect,
    reportDateRangeSelect
  },
  props: {},
  data: function () {
    return {
      overlay: false,
      typeOptions: [
        { value: 'daily', text: '日報' },
        { value: 'monthly', text: '月報' },
        { value: 'yearly', text: '年報' }
      ],
      selectDates: null,
      query: {
        type: 'daily',
        meterTypes: null,
        dist: [],
        schools: []
      },
      items: [],
      meters: []
    }
  },
  computed: {
    ...mapGetters({
      tags: 'page/tags',
      schools: 'page/schools',
      schoolType: 'config/schoolType',
      meterType: 'device/meterType'
    }),
    distOptions() {
      if (!this.tags || !this.tags.branch) {
        return []
      }
      return this.tags.branch.map((dist) => {
        return {
          text: dist.displayName,
          value: dist.id
        }
      })
    },
    cityId() {
      if (!this.tags || !this.tags.id) {
        return null
      }
      return this.tags.id
    },
    schoolOptions() {
      if (!this.schools) {
        return []
      }
      let schoolOptions = this.schools.map((school) => {
        school.text = school.name
        school.value = school.id
        return school
      })

      schoolOptions = schoolOptions.filter((school) =>
        this.query.dist.includes(school.distId)
      )
      return schoolOptions
    },
    meterTypeOption() {
      return this.meterType
        .filter((type) =>
          ['SCHOOLMETER', 'ACMETER', 'SOLARMETER'].includes(type.id)
        )
        .concat({ id: 'ALL', name: '總用電' })
    },
    searchParams() {
      return {
        ids: this.query.schools,
        type: this.query.type,
        meterType: this.query.meterTypes,
        from: this.selectDates[0],
        to: this.selectDates[1] || this.selectDates[0]
      }
    }
  },
  watch: {
    'query.dist'() {
      const schoolOptionsId = this.schoolOptions.map((school) => school.value)
      this.query.schools = this.query.schools.filter((school) => {
        return schoolOptionsId.includes(school)
      })
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    ...mapActions({
      setOverlayLoading: 'page/setOverlayLoading'
    }),
    reset() {
      this.query = {
        type: null,
        meter: [],
        dist: [],
        schools: []
      }
      this.selectDates = []
    },
    async getSchoolMeters() {
      const validate = this.$refs.form.validate()
      if (!validate) return
      this.meters = []
      this.setOverlayLoading(true)
      for (let i = 0; i < this.query.schools.length; i++) {
        try {
          let { data } = await Meter.getSchoolMeters(this.query.schools[i])
          data = data.sort(function (a, b) {
            return a.seq - b.seq
          })
          if (this.query.meterTypes !== 'ALL') {
            data = data.filter(({ type }) => type === this.query.meterTypes)
          }
          this.meters = this.meters.concat(data)
        } catch (error) {
          console.error(error)
        }
      }

      this.setOverlayLoading(false)
    },
    async getReport() {
      const validate = this.$refs.form.validate()
      if (!validate) return
      this.setOverlayLoading(true)
      try {
        const { data } = await Meter.cityReport(this.searchParams)
        this.items = data
      } catch (error) {
        console.error(error)
      } finally {
        this.setOverlayLoading(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  .search-card {
    .v-input {
      input,
      textarea {
        color: var(--v-ems-accent-secondary-base);
      }
      &.v-text-field .v-input__control .v-input__slot {
        box-shadow: none !important;
        border-color: #a1b8d9;
        border-style: solid;
        border-width: 1px;
        ::placeholder {
          color: var(--v-ems-accent-secondary-lighten2);
        }
        .v-select__selection {
          color: var(--v-accent-base);
          font-weight: 700;
        }
      }

      &.error--text {
        &.v-text-field .v-input__control .v-input__slot {
          border-color: var(--v-error-base);
        }
      }
    }
  }
}
</style>
