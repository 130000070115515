var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.tableData,"items-per-page":_vm.perPage,"page":_vm.page,"mobile-breakpoint":"0","footer-props":{
    'show-current-page': false,
    'show-first-last-page': false,
    'items-per-page-options': [_vm.perPage]
  }},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.download",fn:function(ref){
  var item = ref.item;
return [_c('downloadBtn',{attrs:{"value":item}})]}},{key:"footer.page-text",fn:function(ref){
  var itemsLength = ref.itemsLength;
return [_c('v-row',{staticClass:"ma-0",attrs:{"align":"center","justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{staticClass:"elevation-0",attrs:{"items":Array.from(
              { length: Math.ceil(itemsLength / _vm.perPage) },
              function (x, i) { return i + 1; }
            ),"solo":"","dense":"","hide-details":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"auto"}},[_vm._v(" / ")]),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(Math.ceil(itemsLength / _vm.perPage))+" ")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }