<template>
  <v-select
    multiple
    solo
    :items="items"
    v-model="selected"
    :color="color"
    :menu-props="menuProps"
    @click="onClick"
    :rules="[(v) => !!v.length || '此欄位為必填']"
  >
    <template v-slot:label>
      選擇學校<span class="error--text">*</span>
    </template>
    <template v-slot:prepend-item>
      <v-treeview
        v-if="items.length > 0"
        v-model="selected"
        selectable
        open-all
        item-key="value"
        item-text="text"
        :items="schoolTreeOptions"
      ></v-treeview>
      <div v-else class="ma-3">無資料</div>
    </template>
    <template v-slot:selection="{ item }">
      <span v-if="showItem" :class="`${color}--text`">{{ item.text }}</span>
    </template>
  </v-select>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    value: {
      type: Array
    },
    items: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: 'primary'
    },
    menuProps: {
      type: Object,
      default: () => ({ bottom: true, offsetY: true })
    }
  },
  data: function () {
    return {
      selected: this.value,
      showItem: true
    }
  },
  computed: {
    ...mapGetters({
      schoolType: 'config/schoolType'
    }),
    schoolTreeOptions() {
      const otpions = this.schoolType.map((type) => {
        return {
          value: type.key,
          text: type.name,
          children: this.items.filter((school) => school.type === type.key)
        }
      })

      return [
        {
          value: 'ALL',
          text: '所有各級學校',
          children: otpions.filter((otpion) => otpion.children.length > 0)
        }
      ]
    }
  },
  watch: {
    value(val) {
      this.selected = val
    },
    selected(val) {
      this.commit()
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    commit() {
      this.$emit('input', this.selected)
    },
    onClick() {
      this.showItem = false
      this.$nextTick(() => {
        this.showItem = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  .v-list-item {
    display: none;
  }
  .v-select__selections {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline;
    color: var(--v-accent-base);
    font-weight: 700;
    span + span:before {
      content: ', ';
    }
    > input {
      width: auto;
    }
  }
}
</style>
