<template>
  <v-menu offset-y rounded="0" width="80" max-width="80">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        text
        v-bind="attrs"
        v-on="on"
        :loading="loadingDownload"
      >
        <v-icon>mdi-tray-arrow-down</v-icon>
      </v-btn>
    </template>
    <v-list color="accent" dark class="pa-0" width="80" max-width="80">
      <v-list-item @click="exportFile('csv')">
        <v-list-item-title>CSV</v-list-item-title>
      </v-list-item>
      <v-list-item @click="exportFile('json')">
        <v-list-item-title>JSON</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Meter from '@/api/ems/Meter'
import Swal from 'sweetalert2'

export default {
  props: {
    value: {
      type: Object,
      require: true
    }
  },
  data: function () {
    return {
      item: this.value,
      typeOptions: [
        { value: 'daily', text: '日報' },
        { value: 'monthly', text: '月報' },
        { value: 'yearly', text: '年報' }
      ],
      loadingDownload: false
    }
  },
  watch: {
    value(val) {
      this.item = val
    }
  },
  methods: {
    async exportFile(fileType) {
      this.loadingDownload = true
      try {
        const response = await Meter.schoolStatisticExport(
          this.value.meter.id,
          this.value.reportType,
          fileType,
          {
            isSchoolMeter: true,
            date: this.value.from
          }
        )
        this.downloadFile(response, fileType)
      } catch (error) {
        console.error(error)
        Swal.fire({
          text: `資料載入失敗，請重新下載`,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          customClass: {
            popup: 'format-swal'
          }
        })
      }
      this.loadingDownload = false
    },
    downloadFile(response, fileType) {
      if (fileType === 'csv') {
        const url = window.URL.createObjectURL(
          new Blob([(response.data ? '\ufeff' : '') + response.data], {
            type: 'text/csv;charset=utf-8;'
          })
        )
        const link = document.createElement('a')
        link.href = url
        let fileName = this.getResponseFilename(fileType)
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      } else if (fileType === 'json') {
        var dataStr =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(response.data))
        var downloadAnchorNode = document.createElement('a')
        downloadAnchorNode.setAttribute('href', dataStr)
        let fileName = this.getResponseFilename(fileType)
        downloadAnchorNode.setAttribute('download', fileName)
        document.body.appendChild(downloadAnchorNode) // required for firefox
        downloadAnchorNode.click()
        downloadAnchorNode.remove()
      }
    },
    getResponseFilename(fileType) {
      let reportType = this.typeOptions.find(
        (type) => type.value === this.value.reportType
      ).text
      let date = ''
      if (this.value.reportType === 'monthly')
        date = `${this.value.from.split('-')[0]}-${
          this.value.from.split('-')[1]
        }`
      else if (this.value.reportType === 'yearly')
        date = `${this.value.from.split('-')[0]}`
      else date = this.value.from
      return `${this.value.schoolName}_${this.value.meter.name}_${reportType}_${date}.${fileType}`
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  .v-list-item:hover {
    background-color: var(--v-primary-base);
  }
}
</style>
