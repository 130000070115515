<template>
  <v-data-table
    :headers="headers"
    :items="tableData"
    :items-per-page="perPage"
    class="elevation-0"
    :page.sync="page"
    mobile-breakpoint="0"
    :footer-props="{
      'show-current-page': false,
      'show-first-last-page': false,
      'items-per-page-options': [perPage]
    }"
  >
    <template v-slot:[`item.download`]="{ item }">
      <downloadBtn :value="item" />
    </template>
    <template v-slot:[`footer.page-text`]="{ itemsLength }">
      <v-row class="ma-0" align="center" justify="end">
        <v-col cols="auto">
          <v-select
            v-model="page"
            :items="
              Array.from(
                { length: Math.ceil(itemsLength / perPage) },
                (x, i) => i + 1
              )
            "
            solo
            dense
            hide-details
            class="elevation-0"
          ></v-select>
        </v-col>
        <v-col cols="auto" class="pa-0"> / </v-col>
        <v-col cols="auto">
          {{ Math.ceil(itemsLength / perPage) }}
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import downloadBtn from './downloadBtn'

export default {
  components: {
    downloadBtn
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    perPage: {
      type: Number,
      default: 10
    },
    searchDateFrom: {
      type: String,
      default: ''
    },
    reportType: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      page: 1,
      headers: [
        { text: '區域', sortable: true, value: 'schoolDistrict' },
        { text: '學校名稱', sortable: true, value: 'schoolName' },
        { text: '電表類型', sortable: true, value: 'meterTypeName' },
        { text: '報表下載', sortable: false, value: 'download' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      meterType: 'device/meterType',
      tags: 'page/tags',
      schools: 'page/schools'
    }),
    tableData() {
      return this.value.map((meter) => {
        return {
          schoolDistrict: this.tags.branch.find(
            ({ id }) => meter.districtId === id
          ).displayName,
          schoolName: this.schools.find(({ id }) => meter.schoolId === id).name,
          meterTypeName: this.meterType.find(({ id }) => meter.type === id)
            .name,
          meter: meter,
          from: this.searchDateFrom,
          reportType: this.reportType
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  &.v-data-table {
    background-color: transparent !important;
  }
  &.v-data-table > .v-data-table__wrapper > table {
    border-collapse: separate;
    border-spacing: 0 0.5em;
    th,
    td {
      border-bottom: none !important;
    }
    td {
      background-color: #fff;
      color: var(--v-accent-base);
      font-size: 1rem !important;
    }
  }
  .v-text-field > .v-input__control > .v-input__slot {
    box-shadow: none !important;
    input {
      width: 0;
    }
  }
}
</style>
